* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato';
  background-color: #F5E8DC; 
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1, h2, h3 {
  font-family: 'Lilita One', sans-serif;
}

h4, h5, h6 {
  font-family: "Grechen Fuemen", cursive;
}
